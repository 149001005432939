import Vue from 'vue'
import Vuex from 'vuex'

import viewContron from './viewContron.js';
import menuContron from './menuContron.js';

Vue.use(Vuex)

export default new Vuex.Store({
	// 仓库数据本身
	state: {
		//人员管理
		personIsshowAdd: false, //人员管理添加
		personIsshowDel: false, //人员管理编辑
		personId: 0,
		//  照明管理
		SingleLightSchemeAdd: false, //单灯添加
		SingleLightSchemeDel: false, //单灯编辑
		SingleLightSchemeDelID: 0,
		SingleLightSchemeAddID: 0, //判断新增日方案，天文方案

		SingleLightplanAdd: false, //单灯计划添加
		SingleLightplanDel: false, //单灯计划编辑
		SingleLightplanId: 0,
		SingleLightplanDelID: 0,
		isEdit: false,

		LoopSchemeAdd: false, //回路添加
		LoopSchemeDel: false, //回路编辑
		LoopSchemeId: 0,
		LoopSchemeDelID: 0,
		LoopSchemeAddID: 0, //判断新增日方案，天文方案

		LoopplanAdd: false, //单灯计划添加
		LoopplanDel: false, //单灯计划编辑
		LoopplanId: 0,
		LoopplanDelID: 0,

		//组织信息
		OrganizationalDateAdd: false, //组织信息添加
		OrganizationalDateDel: false, //组织信息编辑
		OrganizationalDateId: 0,
		OrganizationalbumenId: 0, //登录组织id

		//角色权限
		PermissionIsshowAdd: false, //人员管理添加
		PermissionIsshowDel: false, //人员管理编辑
		PermissionId: 0,

		// 顶部导航显示
		indexNum: 1,
		mapshow: false, //地图展示  
		// 资产管理
		DeviceListAdd: false, //设备
		DeviceListDel: false,
		DeviceListDelID: 0,
	},
	// 修改仓库数据的唯一方式
	mutations: {
		RESET_STATE: (state) => {
			Object.assign(state, getDefaultState())
		  },
	},
	// 仓库中的过滤器
	getters: {

	},
	// 异步修改仓库数据， 最终还是通过mutations修改仓库数据
	actions: {},
	// 合并状态机
	modules: {
		viewContron,
		menuContron
	}
})
