import req from "@/utils/req";
import {
  employee,
  SystemSetting,
  permis,
  role,
  assets,
  project,
  plan,
  sheme,
  sceen,
  datacenter,
  multimedia,
} from "@/apis/urls";
// 员工管理模块接口
export const PersonApi = {
  // 获取员工列表
  userList_api: (params) => {
    return req.get(employee.userList, params);
  },
  // 删除员工
  userRemove_api: (params) => {
    return req.post(employee.userRemove, params);
  },
  // 员工信息
  userGet_api: (params) => {
    return req.get(employee.userGet, params);
  },
  // 添加员工
  userAdd_api: (params) => {
    return req.post(employee.userAdd, params);
  },
  // 更新员工信息
  userPut_api: (params) => {
    return req.post(employee.userPut, params);
  },
};

// 资产管理模块
export const AssetsApi = {
  // 设备列表
  productlist: (params) => {
    return req.get(assets.productlist, params);
  },
  // 设备类型
  typeList: (params) => {
    return req.get(assets.typeList, params);
  },
  // 集中设备
  centralList: (params) => {
    return req.get(assets.centralList, params);
  },
  // 单灯设备
  singleList: (params) => {
    return req.get(assets.singleList, params);
  },
  // 项目列表接口
  product_projectList: (params) => {
    return req.get(assets.product_projectList, params);
  },
  // 项目片区二级联动
  projectarea_areaList: (params) => {
    return req.get(assets.projectarea_areaList, params);
  },
  // 添加集中控制器
  addCentral: (params) => {
    return req.post(assets.addCentral, params);
  },
  // 集中控制器编辑页面信息
  centralInfo: (params) => {
    return req.get(assets.centralInfo, params);
  },
  // 修改集中控制器信息
  modifyCentral: (params) => {
    return req.post(assets.modifyCentral, params);
  },
  // 删除集中控制器
  removeCentral: (params) => {
    return req.get(assets.removeCentral, params);
  },
  // 添加单灯控制器
  addSingle: (params) => {
    return req.post(assets.addSingle, params);
  },
  // 单灯控制器编辑页面详情
  singleInfo: (params) => {
    return req.get(assets.singleInfo, params);
  },
  // 修改单灯控制器信息
  modifySingle: (params) => {
    return req.post(assets.modifySingle, params);
  },
  // 删除单灯控制器信息
  removeSingle: (params) => {
    return req.get(assets.removeSingle, params);
  },
  // 修改项目片区
  projectarea_modify: (params) => {
    return req.post(assets.projectarea_modify, params);
  },
  // 编辑项目片区
  projectarearemove: (params) => {
    return req.post(assets.projectarearemove, params);
  },
  // 尝试激活单灯、集中控制器
  activeDevice: (params) => {
    return req.post(assets.activeDevice, params);
  },
  // IP广播-列表
  ipRadioList: (params) => {
    return req.get(assets.ipRadioList, params);
  },
  // IP广播-添加
  addipRadio: (params) => {
    return req.post(assets.addipRadio, params);
  },
  // IP广播-信息
  ipRadioInfo: (params) => {
    return req.get(assets.ipRadioInfo, params);
  },
  // IP广播-编辑
  modifyipRadio: (params) => {
    return req.post(assets.modifyipRadio, params);
  },
  // IP广播-删除
  removeipRadio: (params) => {
    return req.get(assets.removeipRadio, params);
  },
  // IP广播-绑定呼叫设备
  modifyIpRadio: (params) => {
    return req.post(assets.modifyIpRadio, params);
  },
  // 灯杆-列表
  lampPostList: (params) => {
    return req.get(assets.lampPostList, params);
  },
  // 灯杆-添加
  addLampPost: (params) => {
    return req.post(assets.addLampPost, params);
  },
  // 灯杆-信息
  lampPostInfo: (params) => {
    return req.get(assets.lampPostInfo, params);
  },
  // 灯杆-编辑
  modifyLampPost: (params) => {
    return req.post(assets.modifyLampPost, params);
  },
  // 灯杆-删除
  removeLampPost: (params) => {
    return req.get(assets.removeLampPost, params);
  },
  // 灯杆-模糊查询
  lampPostLike: (params) => {
    return req.get(assets.lampPostLike, params);
  },
};
// 系统设置模块
export const SystemSetApi = {
  // 获取角色列表
  roleList_api: (params) => {
    return req.get(role.roleList, params);
  },
  // 获取角色信息及菜单和接口权限tree
  roleGet_api: (params) => {
    return req.get(role.roleGet, params);
  },
  // 添加角色及权限
  roleAdd_api: (params) => {
    return req.post(role.roleAdd, params);
  },
  // 编辑角色及权限
  roleModify_api: (params) => {
    return req.post(role.roleModify, params);
  },
  // 删除角色及权限
  roleRemove_api: (params) => {
    return req.post(role.roleRemove, params);
  },
  // 获取角色权限
  roleetrolepermission_api: (params) => {
    return req.get(role.roleetrolepermission, params);
  },
  // 省市
  pcaget_api: (params) => {
    return req.post(SystemSetting.pcaget, params);
  },
  // 更新组织
  orgModify_api: (params) => {
    return req.post(SystemSetting.orgModify, params);
  },
  // 部门列表
  branchList_api: (params) => {
    return req.post(SystemSetting.branchList, params);
  },
  // 部门列表信息
  branchgetList_api: (params) => {
    return req.get(SystemSetting.branchgetList, params);
  },
  // 添加部门
  branchAdd_api: (params) => {
    return req.post(SystemSetting.branchAdd, params);
  },
  // 修改部门
  branchModify_api: (params) => {
    return req.post(SystemSetting.branchModify, params);
  },
  // 删除部门
  branchRemove_api: (params) => {
    return req.post(SystemSetting.branchRemove, params);
  },
  // 菜单
  menuget: (params) => {
    return req.get(SystemSetting.menuget, params);
  },
  // 登录
  login_api: (params) => {
    return req.post(permis.login, params);
  },
  // 获取组织列表
  orgList_api: (params) => {
    return req.get(permis.orgList, params);
  },
  // 获取组织信息
  orgGet_api: (params) => {
    return req.get(permis.orgGet, params);
  },
  // 修改
  orgModify_api: (params) => {
    return req.post(permis.orgModify, params);
  },
  // 登出
  authLogout_api: (params) => {
    return req.post(permis.authLogout, params);
  },
  // 切换组织
  changeorg: (params) => {
    return req.post(permis.changeorg, params);
  },
};

// 项目管理模块API
export const ProjectApi = {
  // 项目列表
  project_list: (params) => {
    return req.get(project.project_list, params);
  },
  // 项目详情
  projectget: (params) => {
    return req.get(project.projectget, params);
  },
  // 项目编辑
  projectmodify: (params) => {
    return req.post(project.projectmodify, params);
  },
  // 新增项目
  projectadd: (params) => {
    return req.post(project.projectadd, params);
  },
  // 移除项目
  projectremove: (params) => {
    return req.post(project.projectremove, params);
  },
  // 获取片区列表
  projectarealist: (params) => {
    return req.get(project.projectarealist, params);
  },
  // 获取片区详情
  projectareaget: (params) => {
    return req.get(project.projectareaget, params);
  },
};
// 照明管理接口
export const IlluminationApi = {
  // 计划
  // 添加
  ctrlPlanAdd: (params) => {
    return req.post(plan.ctrlPlanAdd, params);
  },
  // 计划类型列表
  ctrlPlantypeList: (params) => {
    return req.get(plan.ctrlPlantypeList, params);
  },
  // 编辑计划
  ctrlPlanModify: (params) => {
    return req.post(plan.ctrlPlanModify, params);
  },
  // 计划详情
  ctrlPlanInfo: (params) => {
    return req.get(plan.ctrlPlanInfo, params);
  },
  // 计划删除
  ctrlPlanRemove: (params) => {
    return req.get(plan.ctrlPlanRemove, params);
  },
  // 计划列表
  ctrlPlanList: (params) => {
    return req.get(plan.ctrlPlanList, params);
  },
  // 方案列表
  ctrlPlanschemeList: (params) => {
    return req.get(plan.ctrlPlanschemeList, params);
  },
  // 获取设备列表
  ctrlPlanproductList: (params) => {
    return req.get(plan.ctrlPlanproductList, params);
  },

  // 方案
  // 方案类型列表
  ctrlSchemeTypeList: (params) => {
    return req.get(sheme.ctrlSchemeTypeList, params);
  },
  // 方案类型添加
  ctrlSchemeAdd: (params) => {
    return req.post(sheme.ctrlSchemeAdd, params);
  },
  // 方案列表
  ctrlSchemeList: (params) => {
    return req.get(sheme.ctrlSchemeList, params);
  },
  // 编辑方案
  ctrlSchemeModify: (params) => {
    return req.post(sheme.ctrlSchemeModify, params);
  },
  // 方案详情
  ctrlSchemeInfo: (params) => {
    return req.get(sheme.ctrlSchemeInfo, params);
  },
  // 方案删除
  ctrlSchemeRemove: (params) => {
    return req.get(sheme.ctrlSchemeRemove, params);
  },
};

// 数据大屏接口
export const sceenApi = {
  // 天气
  getWeather: (params) => {
    return req.get(sceen.getWeather, params);
  },
  // 设备状态统计
  deviceStatusStat: (params) => {
    return req.get(sceen.deviceStatusStat, params);
  },
  // 资产统计
  assetStat: (params) => {
    return req.get(sceen.assetStat, params);
  },
  // 单灯列表
  lampList: (params) => {
    return req.get(sceen.lampList, params);
  },
  // 单灯详情
  lampInfo: (params) => {
    return req.get(sceen.lampInfo, params);
  },
  // 能耗统计
  energyStat: (params) => {
    return req.get(sceen.energyStat, params);
  },
};

// 数据中心
export const datacenterApi = {
  // 数据中心-数据总览-顶部总览
  getTotalStats: (params) => {
    return req.get(datacenter.getTotalStats, params);
  },
  // 数据中心-数据总览-能耗走势
  getEnergyTrade: (params) => {
    return req.get(datacenter.getEnergyTrade, params);
  },
  // 数据中心-统计报告列表
  reportSchemeList: (params) => {
    return req.get(datacenter.reportSchemeList, params);
  },
  // 数据中心-资产统计报告详情
  deviceReportDetail: (params) => {
    return req.get(datacenter.deviceReportDetail, params);
  },
  // 数据中心-能耗统计报告详情
  energyReportDetail: (params) => {
    return req.get(datacenter.energyReportDetail, params);
  },
};

// 多媒体管理
export const multimediaApi = {
  // 素材库列表
  mediaList: (params) => {
    return req.get(multimedia.mediaList, params);
  },
  // 素材库删除
  removeMedia: (params) => {
    return req.get(multimedia.removeMedia, params);
  },

  // 多媒体素材库-添加
  addMedia: (params) => {
    return req.post(multimedia.addMedia, params);
  },

  // 多媒体素材库-信息
  mediaInfo: (params) => {
    return req.post(multimedia.mediaInfo, params);
  },

  // 广播计划-添加
  addRadioPlan: (params) => {
    return req.post(multimedia.addRadioPlan, params);
  },

  // 广播计划-编辑
  modifyRadioPlan: (params) => {
    return req.post(multimedia.modifyRadioPlan, params);
  },

  // 广播计划-列表
  radioPlanList: (params) => {
    return req.get(multimedia.radioPlanList, params);
  },

  // 广播计划-删除
  removeRadioPlan: (params) => {
    return req.get(multimedia.removeRadioPlan, params);
  },

  // 广播计划-信息
  radioPlanInfo: (params) => {
    return req.get(multimedia.radioPlanInfo, params);
  },
};
