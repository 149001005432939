//请求封装接口api工具js文件
// 封装get和podt请求
// 设置拦截器
// 设置默认地址
import axios from "axios";
import local from "@/utils/local";
import { Message } from "element-ui";
import router from "@/router/index";
//创建axios实例
const axiosEmplate = axios.create({
  timeout: 100000,
});
// 设置请求拦截器
axiosEmplate.interceptors.request.use((config) => {
  // 从本地取出token 在登录成功后，把token存入本地，在请求拦截器中取出token
  let tk = local.get("tk");
  // 判断，如果token存在，设置在请求头中
  if (tk) {
    // console.log(config)
    config.headers.Authorization = tk;
  }
  return config;
});

// 设置响应拦截器
axiosEmplate.interceptors.response.use(
  async (config) => {
    console.log(config);
    // 成功回调，统一弹窗处理
    let { code, msg } = config.data;
    console.log(code);
    console.log(msg);
    if (code != undefined && msg != undefined) {
      if (code == 0) {
      } else if (code == "00" || code == "11") {
        // 不操作
      } else if (code == "1003" || code == "1005" || code == "1002" || code == "1001" || code == "1009") {
        Message.error(msg);
        sessionStorage.clear();
        let acc = local.get("acc");
        let password = local.get("password");
        localStorage.clear();
        if (acc && password) {
          local.set("acc", acc);
          local.set("password", password);
        }
        router.push("/login");
      }
    }
    return config;
  },
  (res) => {
    if (res.response.data.code == 1001) {
      local.clear();
    }
  }
);
//设置默认地址
let url = window.location.href;
if (/hqlights\.haorantech\.cn/.test(url)) {
  axiosEmplate.defaults.baseURL = "http://hqlights-api.haorantech.cn";
} else {
  axiosEmplate.defaults.baseURL = "http://hqlights-api-dev.haorantech.cn";
}

axiosEmplate.contentType = "application/json";
// axiosEmplate.defaults.withCredentials = true;
export default {
  // get请求
  get(url, params) {
    return new Promise((resolve, reject) => {
      axiosEmplate
        .get(url, { params })
        .then((res) => {
          resolve(res.data); //get请求必须是对象params 一般只写res
        })
        .catch((erro) => {
          reject(erro);
        });
    });
  },
  // post请求
  post(url, params) {
    return new Promise((resolve, reject) => {
      axiosEmplate
        .post(url, params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((erro) => {
          reject(erro);
        });
    });
  },
  header(url, methods, params) {
    return axiosEmplate({
      url: url,
      method: methods,
      headers: params,
    });
  },

  delete(url, params) {
    return new Promise((resolve, reject) => {
      axiosEmplate
        .delete(url, params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((erro) => {
          reject(erro);
        });
    });
  },
};
