import Vue from "vue";
import VueRouter from "vue-router";
import local from "@/utils/local";
import versionTood from "@/utils/versionUpdate";
import asyncRoutes from "./route";
Vue.use(VueRouter);

const router = new VueRouter({
  routes: asyncRoutes,
});

router.beforeEach((to, from, next) => {
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion();
  let tk = local.get("tk");
  if (to.path == "/login") {
    next();
  } else if (tk) {
    next();
  } else {
    router.push("/login");
  }
  next();
});

// 点击相同路由报警告的解决办法
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
