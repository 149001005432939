<template>
  <el-container v-if="reload" style="height: 100%; background-color: #f8f8f8">
    <el-header>
      <ConstructionTop></ConstructionTop>
    </el-header>
    <el-container style="height: 100%">
      <el-aside>
        <Aside></Aside>
      </el-aside>
      <el-main>
        <div class="content">
          <div class="warp">
            <div class="rview">
              <router-view v-if="!$route.meta.keepAlive"></router-view>
              <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
              </keep-alive>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import ConstructionTop from "@/components/ConstructionTop.vue";
import Aside from "@/components/Aside.vue";
import { mapMutations, mapState } from "vuex";
import { SystemSetApi } from "@/apis/index";
export default {
  components: {
    ConstructionTop,
    Aside,
  },
  data() {
    return {
      reload: true,
    };
  },
  created() {},
  computed: {
    ...mapState({
      viewReload: (state) => state.viewContron.viewReload,
    }),
  },
  watch: {
    viewReload: {
      handler(newVal) {
        if (newVal) {
          this.reload = false;
          this.$nextTick(() => {
            this.reload = true;
            this.setviewReload(false);
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      setviewReload: "viewContron/setviewReload",
      setViews: "viewContron/setViews",
    }),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-container {
  height: 100% !important;
}

body,
html {
  height: 100%;
  user-select: auto !important;
}

::v-deep .el-header {
  background: #fff;
  width: 100vw;
  height: 85px !important;
  padding: 0;
}

::v-deep .el-aside {
  height: 100%;
  width: 251px !important;
  overflow-x: hidden;
  background-color: rgb(33, 49, 154);
}

::v-deep .el-main {
  padding: 0;
  padding-left: 10px;
  background: #fff;
  margin: 10px 10px 0 10px;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px;
    /*高宽分别对应横竖滚动条的尺寸*/
    // height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    background: rgba(173, 178, 189, 0.39);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
    // border-radius: 10px;
    background: #ededed;
  }
}

.content {
  height: 100%;
}

.warp {
  background-color: #fff;
  height: 100%;
  padding: 0px 20px;
}

.rview {
  padding-top: 10px;
}
</style>
