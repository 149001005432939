// 设备判断
export default function remSize(noRest) {
  let winWidth;
  // 获取窗口宽度
  if (window.innerWidth) {
    winWidth = window.innerWidth;
  } else if (document.body && document.body.clientWidth) {
    winWidth = document.body.clientWidth;
  }
  // 通过深入Document内部对body进行检测，获取窗口大小
  if (
    document.documentElement &&
    document.documentElement.clientHeight &&
    document.documentElement.clientWidth
  ) {
    winWidth = document.documentElement.clientWidth;
  }
  // 修改font-size
  const fontSize = ((winWidth / 1920) * 15).toFixed(4);
  document.documentElement.style.fontSize = `${fontSize}px`;
  console.log(fontSize, "setResize");
}
