const state = {
	menus: [
		{name: ""}
	],
	clickMenu: {},
	cureentMenuParent: {},
	cureentMenuChild: {},
	isReload: true, // 进入编辑页面变为false，不需要重新获得菜单
}

const getter = {}

const mutations = {
	setMenus(state, data) {
		state.menus = data;
	},
	setClickMenu(state, data) {
		state.clickMenu = data;
	},
	setCureentMenuParent(state, data) {
		state.cureentMenuParent = data;
	},
	setCureentMenuChild(state, data) {
		state.cureentMenuChild = data;
	},
	setIsReload(state, data) {
		state.isReload = data;
	},
}

const actions = {}

export default {
	namespaced: true,
	state,
	getter,
	mutations,
	actions
}
