// 人员管理
export const employee = {
  userList: "/user/list", // 获取员工列表
  userGet: "/user/getUserInfo", // 员工信息
  userAdd: "/user/add", // 添加员工
  userPut: "/user/modify", // 更新员工信息
  userRemove: "/user/remove", // 删除员工,
};
// 照明计划
export const plan = {
  ctrlPlanAdd: "/ctrlPlan/add", // 计划类型添加
  ctrlPlantypeList: "/ctrlPlan/typeList", // 计划类型列表
  ctrlPlanModify: "/ctrlPlan/modify", // 编辑计划
  ctrlPlanInfo: "/ctrlPlan/info", // 计划详情
  ctrlPlanRemove: "/ctrlPlan/remove", // 计划删除
  ctrlPlanList: "/ctrlPlan/List", // 计划列表
  ctrlPlanschemeList: "/ctrlPlan/schemeList", // 方案列表
  ctrlPlanproductList: "/ctrlPlan/deviceList", // 获取设备列表
};
// 照明方案
export const sheme = {
  ctrlSchemeTypeList: "/ctrlScheme/schemeTypeList", // 方案类型列表
  ctrlSchemeAdd: "/ctrlScheme/add", // 方案类型添加
  ctrlSchemeList: "/ctrlScheme/list", // 方案列表
  ctrlSchemeModify: "/ctrlScheme/modify", // 编辑方案
  ctrlSchemeInfo: "/ctrlScheme/info", // 方案详情
  ctrlSchemeRemove: "/ctrlScheme/remove", // 方案删除
};
// 系统设置
export const SystemSetting = {
  pcaget: "/pca/get", // 省市
  orgModify: "/org/modify", // 更新组织
  branchList: "/branch/list", // 部门列表
  branchgetList: "/branch/get", // 部门列表信息
  branchAdd: "/branch/add", // 添加部门
  branchModify: "/branch/modify", // 修改部门
  branchRemove: "/branch/remove", // 删除部门
  menuget: "/menu/get", // 菜单
};
// 登录相关
export const permis = {
  login: "/auth/login", // 登录
  orgList: "/org/list", // 获取组织列表
  orgGet: "/org/get", // 获取组织信息
  orgModify: "/org/modify", // 修改
  authLogout: "/auth/logout", // 登出
  changeorg: "/auth/changeorg", // 切换组织
};
// 角色权限
export const role = {
  roleList: "/role/list", // 获取角色列表
  roleGet: "/role/get", // 获取角色信息及菜单和接口权限tree
  roleAdd: "/role/add", // 添加角色及权限
  roleModify: "/role/modify", // 编辑角色及权限
  roleRemove: "/role/remove", // 删除角色及权限
  roleetrolepermission: "/role/getrolepermission", // 获取角色权限
};
// 资产管理
export const assets = {
  productlist: "/device/list", //
  typeList: "/device/typeList", // 设备类型
  centralList: "/device/centralList", // 集中设备
  singleList: "/device/singleList", // 单灯设备
  product_projectList: "/device/projectList", // 项目列表接口
  projectarea_areaList: "/projectarea/areaList", // 项目片区二级联动
  addCentral: "/device/addCentral", // 添加集中控制器
  centralInfo: "/device/centralInfo", // 集中控制器编辑页面信息
  modifyCentral: "/device/modifyCentral", // 修改集中控制器信息
  removeCentral: "/device/removeCentral", // 删除集中控制器
  addSingle: "/device/addSingle", // 添加单灯控制器
  singleInfo: "/device/singleInfo", // 单灯控制器编辑页面详情
  modifySingle: "/device/modifySingle", // 修改单灯控制器信息
  removeSingle: "/device/removeSingle", // 删除单灯控制器信息
  projectarea_modify: "/projectarea/modify", // 修改项目片区
  projectarearemove: "/projectarea/remove", // 删除项目片区
  activeDevice: "/device/activeDevice", // 尝试激活单灯、集中控制器

  ipRadioList: "/ipRadio/ipRadioList", //  IP广播-列表
  addipRadio: "/ipRadio/addipRadio", // IP广播-添加
  ipRadioInfo: "/ipRadio/ipRadioInfo", // IP广播-信息
  modifyipRadio: "/ipRadio/modifyipRadio", // IP广播-编辑
  removeipRadio: "/ipRadio/removeipRadio", // IP广播-删除
  modifyIpRadio: "/ipRadio/modifyIpRadio", // IP广播-绑定呼叫设备

  lampPostList: "/lampPost/lampPostList", // 灯杆-列表
  addLampPost: "/lampPost/addLampPost", // 灯杆-添加
  lampPostInfo: "/lampPost/lampPostInfo", // 灯杆-信息
  modifyLampPost: "/lampPost/modifyLampPost", // 灯杆-编辑
  removeLampPost: "/lampPost/removeLampPost", // 灯杆-删除
  lampPostLike: "/lampPost/lampPostLike", // 灯杆-模糊查询
};
// 项目管理
export const project = {
  project_list: "/project/list", // 项目列表
  projectget: "/project/get", // 项目详情
  projectmodify: "/project/modify", // 项目编辑
  projectadd: "/project/add", // 新增项目
  projectremove: "/project/remove", // 移除项目
  projectarealist: "/projectarea/list", // 获取片区列表
  projectareaget: "/projectarea/get", // 获取片区详情
};

// 数据大屏
export const sceen = {
  getWeather: "/stats/getWeather", // 天气
  deviceStatusStat: "/stats/deviceStatusStat", // 设备状态统计
  assetStat: "/stats/assetStat", // 资产统计
  lampList: "/stats/lampList", // 单灯列表
  lampInfo: "/stats/lampInfo", // 单灯详情
  energyStat: "/stats/energyStat", // 能耗统计
};

// 数据中心
export const datacenter = {
  getTotalStats: "/dataCenter/getTotalStats", // 数据中心-数据总览-顶部总览
  getEnergyTrade: "/dataCenter/getEnergyTrade", // 数据中心-数据总览-能耗走势
  reportSchemeList: "/dataCenter/reportSchemeList", // 数据中心-统计报告列表
  deviceReportDetail: "/dataCenter/deviceReportDetail", // 数据中心-资产统计报告详情
  energyReportDetail: "/dataCenter/energyReportDetail", // 数据中心-能耗统计报告详情
};

// 监控
export const monitoring = {
  aialarm: "/aialarm/deal", // 获取报警消息列表
};

// 多媒体管理
export const multimedia = {
  mediaList: "/media/mediaList", // 素材库列表
  removeMedia: "/media/removeMedia", // 素材库删除
  addMedia: "/media/addMedia", // 多媒体素材库-添加
  mediaInfo: "/media/mediaInfo", // 多媒体素材库-信息
  addRadioPlan: "/radioPlan/addRadioPlan", // 广播计划-添加
  modifyRadioPlan: "/radioPlan/modifyRadioPlan", // 广播计划-编辑
  radioPlanList: "/radioPlan/radioPlanList", // 广播计划-列表
  removeRadioPlan: "/radioPlan/removeRadioPlan", // 广播计划-删除
  radioPlanInfo: "/radioPlan/radioPlanInfo", // 广播计划-信息
};
