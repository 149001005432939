const state = {
	views: [],
	viewReload: false
}

const getter = {}

const mutations = {
	setViews(state,payload) {
		state.views = payload;
	},
	setviewReload(state,payload) {
		state.viewReload = payload;
	}
}

const actions = {}

export default {
	namespaced: true,
	state,
	getter,
	mutations,
	actions
}