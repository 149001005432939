const enums = {
  // 单灯方案还是回路方案
  scheme_type: {
    single: 1, // 单灯方案
    circuit: 2, // 回路方案
  },
  // 单灯计划还是回路计划
  plan_type: {
    single: 1, // 单灯计划
    circuit: 2, // 回路计划
  },
  // 临时计划还是常规计划
  exe_type: {
    // 原 plan_tid 字段改成 exe_type(t_ctrl_plan.exe_type)
    regular_plan: 5, // 常规计划
    temp_plan: 6, // 临时计划
  },
  // 日方案还是天文方案
  time_type: {
    // 原 scheme_tid 字段改成 time_type(t_ctrl_scheme.time_type)
    daily: 2, // 日方案
    astronomy: 3, // 天文方案
  },
  // 设备类型
  dev_type: {
    // 原 tid 字段改成 dev_type(t_device.dev_type)
    ad_screen: 1, // 广告发布屏
    ip_radio: 2, // IP广播
    lamppost: 3, // 灯杆
    centra: 8, // 集中控制器
    single: 9, // 单灯控制器
    double: 10, // 双灯控制器
    optical: 11, // 光照传感器
    circuit: 12, // 回路
  },
  //方案使用状态
  scheme_status: {
    none: 0, //无设备使用
    use: 1, //使用中
    plan: 2, //已计划
  },
  //计划使用状态
  plan_status: {
    longTime: 0, //长期
    notStart: 1, //待执行
    afoot: 2, //执行中
    overdue: 3, //已过期
  },
  //设备状态
  device_status: {
    unactive: 0, // 设备未激活。
    offline: 1, // 设备离线。
    online: 2, // 设备在线。
    repair: 3, // 设备维护中。
    disable: 4, // 设备已禁用。
  },
  // 光感自控
  optical_control: {
    off: 0, // 不开启
    on: 1, // 开启
  },
  // 项目状态
  project_area_state: {
    completed: 1, // 已完成
    unbuild: 2, // 待施工
    building: 3, // 施工中
  },
};

export default enums;