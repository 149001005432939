<template>
  <div class="ConstructionTop">
    <div class="loginbox">
      <div class="Constructionlf">
        <el-tooltip
          class="item"
          effect="dark"
          :content="org_title"
          placement="bottom-end"
        >
          <p class="org_title">{{ org_title }}</p>
        </el-tooltip>
        <p class="logoname">智慧照明综合管理平台</p>
      </div>
      <!-- <el-dropdown @command="switchInfo" trigger="click">
        <div class="switch">
          <p>切换</p>
          <div class="sic">
            <img src="@/static/imgs/zu3585@2x.png" alt="" />
          </div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in userInfo.userOrgs"
            :key="index"
            :command="item"
          >
            {{ item.org_title }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <div class="menubox f-c">
      <div class="Constructionct">
        <div v-for="(item, index) in views" :key="index" class="menuitem">
          <div
            v-if="item.is_permission"
            @click="ConstructionNav(item)"
            :class="{
              ConstructionNav: true,
              on: currentIndex == index && item.name != '数据大屏',
            }"
          >
            <!-- <a :href="item.path" @click.prevent>{{ item.name }}</a> -->
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="userbox f-c">
        <div class="notice">
          <el-badge :value="0" :max="99" class="item">
            <i class="el-icon-message-solid"></i>
          </el-badge>
        </div>
        <div class="avar">
          <img
            :src="
              userInfo.avatar ||
              'https://bpic.51yuansu.com/pic3/cover/01/85/50/5981488af190c_610.jpg'
            "
          />
        </div>
        <div class="userinfo">
          <el-tooltip
            class="item"
            effect="dark"
            :content="userInfo.name"
            placement="bottom-end"
          >
            <p class="name">{{ userInfo.role_name }}： {{ userInfo.name }}</p>
          </el-tooltip>

          <p class="logout" @click="logout">退出登录</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import local from "@/utils/local";
import { mapMutations, mapState } from "vuex";
import { PersonApi, SystemSetApi } from "@/apis/index";
export default {
  data() {
    return {
      info: {},
      breaderarr: [],
      usename: "",
      OrganizationalbumenId: 0,
      Organizationalformname: "",
      currentIndex: 0,
      userInfo: {},
    };
  },
  created() {
    this.org_title = local.get("org_title");
    this.usename = local.get("usename");
    this.OrganizationalbumenId = local.get("org_id");
    this.breader();
    this.getorgGet();
    this.currentMenuClick();
  },
  computed: {
    ...mapState({
      views: (state) => state.viewContron.views,
      viewReload: (state) => state.viewContron.viewReload,
      clickMenu: (state) => state.menuContron.clickMenu,
      isReload: (state) => state.menuContron.isReload,
    }),
  },
  watch: {
    "$route.path"() {
      this.breader();
    },
    indexchange: {
      handler(newVal) {
        this.index = newVal;
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      setClickMenu: "menuContron/setClickMenu",
      setviewReload: "viewContron/setviewReload",
      setCureentMenuParent: "menuContron/setCureentMenuParent",
      setCureentMenuChild: "menuContron/setCureentMenuChild",
      setIsReload: "menuContron/setIsReload",
      setMenus: "menuContron/setMenus",
    }),
    // 计算面包屑导航的函数
    breader() {
      let arr = [];
      this.$route.matched.forEach((item) => {
        if (item.path !== "") {
          arr.push({
            path: item.path,
            title: item.meta.title,
          });
        }
      });
      this.breaderarr = arr;
    },
    async logout() {
      try {
        const data = await SystemSetApi.authLogout_api();
        if (data.code == 0) {
          sessionStorage.clear();
          let acc = local.get("acc");
          let password = local.get("password");
          localStorage.clear();
          if (acc && password) {
            local.set("acc", acc);
            local.set("password", password);
          }
          this.setClickMenu({}); // 记录当前点击得NAV
          this.setCureentMenuParent({});
          this.setCureentMenuChild({});
          this.$router.replace("/login");
        }
      } catch (err) {
        throw Error(err);
      }
    },
    ConstructionNav(data) {
      if (data.name == "数据大屏") {
        this.$router.push("/screen");
      } else {
        if (data.name == "维护管理") {
          this.$router.push("/building/index");
        }
        console.log(data);
        this.getmenus(data.id); // 获得当前点击NAV下得菜单
        this.setClickMenu(data); // 记录当前点击得NAV
        this.currentMenuClick();
        this.setCureentMenuParent({});
        this.setCureentMenuChild({});
        this.setIsReload(true);
        localStorage.setItem("store", JSON.stringify(this.$store.state));
      }
    },
    async getmenus(pid) {
      try {
        const data = await SystemSetApi.menuget({
          pid,
        });
        if (data.code == 0) {
          console.log("菜单菜单菜单菜单菜单菜单菜单菜单菜单菜单菜单菜单菜单菜单菜单")
          console.log(data.data);
          this.setMenus(data.data);
        }
      } catch (err) {
        throw Error(err);
      }
    },
    // 组织信息
    async getorgGet() {
      try {
        const data = await PersonApi.userGet_api({
          uid: local.get("user_id"),
        });
        console.log(data.data);
        if (data.code == 0) {
          this.userInfo = data.data;
        }
      } catch (err) {
        throw Error(err);
      }
    },

    currentMenuClick() {
      if (
        this.clickMenu &&
        Object.keys(this.clickMenu).length &&
        this.views.length
      ) {
        this.currentIndex = this.views.findIndex(
          (item) => item.id == this.clickMenu.id
        );
      } else {
        this.currentIndex = 0;
      }
      if (this.views[this.currentIndex]) {
        this.getmenus(this.views[this.currentIndex].id);
      }
    },

    async switchInfo(val) {
      try {
        const data = await SystemSetApi.changeorg({
          org_id: val.org_id,
        });
        if (data.code == 0) {
          local.set("tk", data.data.token);
          local.set("usename", data.data.name);
          local.set("user_id", data.data.uid);
          local.set("org_id", data.data.org_id);
          local.set("region", data.data.org_id);
          local.set("org_title", data.data.org_title);
          this.org_title = data.data.org_title;
          this.setviewReload(true);
        }
      } catch (err) {
        throw Error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.org_title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #303f9f;
}

.logoname {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 300;
  color: #303f9f;
  margin-top: 8px;
}

.loginbox {
  display: flex;
}

.ConstructionTop {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 22px 20px 0 20px;
  justify-content: space-between;

  .Constructionlf {
    font-size: 14px;
    color: #21319a;
    width: 200px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ConstructionlfTtile {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .userbox {
    margin-right: 40px;
    margin-left: 110px;

    .notice {
      margin-right: 20px;
    }
  }

  .Constructionct {
    display: flex;
    justify-content: space-around;

    .menuitem {
      margin-left: 110px;
      font-size: 14px;
      height: 40px;
      line-height: 40px;
    }

    .ConstructionNav {
      text-align: center;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
    }

    .on {
      border-bottom: 4px solid #303f9f;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #303f9f;
    }
  }

  .avar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    vertical-align: middle;
    margin-left: 30px;
    margin-right: 10px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .userinfo {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;

    .name {
      width: 152px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .logout {
    color: #da5a51;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 8px;
  }

  ::v-deep .el-icon-message-solid {
    font-size: 24px;
  }
}

::v-deep .el-dropdown-link {
  color: #21319a;
}

::v-deep .el-icon-message-solid {
  display: inline-block;
  font-size: 15px;
  cursor: pointer;
  color: #21319ac9;
}

.switch {
  width: 76px;
  height: 28px;
  border: 1px solid #383092;
  border-radius: 14px;
  text-align: center;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #383092;
  cursor: pointer;
  margin-left: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  .sic {
    width: 13px;
    height: 13px;
    margin-left: 6px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

::v-deep .el-popper {
  margin-top: 5px !important;
}
</style>
