import Vue from "vue";
import App from "./App";
import ElementUI from "element-ui";
import router from "@/router/index";
import moment from "moment";
import store from "@/store/index.js";
import "element-ui/lib/theme-chalk/index.css";
import "@/static/element-variables.scss";
import "@/static/font/iconfont.js";
import "@/static/font/iconfont.css";
import remSize from "@/utils/rem";
import enums from "@/utils/enums";
//  引入Echarts
import * as echarts from "echarts";
import VueAMap from "vue-amap";
import * as AMaps from "@amap/amap-jsapi-loader";
// import htmlToPdf from "@/utils/pdf";
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  //申请地址 https://lbs.amap.com/ 选择web端jsAPI
  key: "	266a4936626fe6244eac994aef938c15",
  // 插件集合，用到什么插件就使用什么插件
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
  ],
});

Vue.prototype.$echarts = echarts;
Vue.prototype.$AMaps = AMaps;

Vue.prototype.$moment = moment;
Vue.prototype.$enums = enums;
Vue.use(ElementUI);
// Vue.use(htmlToPdf);
Vue.config.productionTip = false;

console.log(process.env.NODE_ENV);

remSize();
window.addEventListener("resize", () => {
  remSize();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
