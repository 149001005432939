import Layout from "@/pages/home/index.vue";
export default [
  {
    path: "/",
    redirect: "/login",
  },
  // 登录
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/login/index.vue"),
  },
  // home
  {
    path: "/home",
    name: "home",
    component: () => import("@/pages/home/index.vue"),
  },
  // 照明管理
  {
    path: "businessManagement",
    meta: {
      title: "照明管理",
    }, //普通和超级都可以访问的一级路由
    isshow: true,
    component: Layout, // 布局Layout
    children: [
      {
        path: "/businessManagement/Singlelightscheme",
        meta: {
          title: "单灯方案",
          keepAlive: true,
        }, // 超级才能访问
        isshow: true,
        component: () =>
          import("@/pages/businessManagement/singlelightscheme/index.vue"),
      },
      {
        path: "/businessManagement/Singlelightscheme/edit",
        meta: {
          title: "单灯方案编辑",
          isedit: true,
        }, // 超级才能访问
        isshow: true,
        name: "SinglelightschemeEdit",
        component: () =>
          import("@/pages/businessManagement/singlelightscheme/edit.vue"),
      },
      {
        path: "/businessManagement/Singlelightplan",
        isshow: true,
        meta: {
          title: "单灯计划",
          keepAlive: true,
        },
        component: () =>
          import("@/pages/businessManagement/singlelightplan/index.vue"),
      },
      {
        path: "/businessManagement/Singlelightplan/edit",
        isshow: true,
        name: "SinglelightplanEdit",
        meta: {
          title: "单灯计划编辑",
          isedit: true,
        },
        component: () =>
          import("@/pages/businessManagement/singlelightplan/edit.vue"),
      },
      {
        path: "/businessManagement/Loopsheme",
        isshow: true,
        meta: {
          title: "回路方案",
          keepAlive: true,
        },
        component: () =>
          import("@/pages/businessManagement/loopsheme/index.vue"),
      },
      {
        path: "/businessManagement/Loopsheme/edit",
        isshow: true,
        name: "LoopshemeEdit",
        meta: {
          title: "回路方案编辑",
          isedit: true,
        },
        component: () =>
          import("@/pages/businessManagement/loopsheme/edit.vue"),
      },
      {
        path: "/businessManagement/Loopplan",
        isshow: true,
        meta: {
          title: "回路计划",
          keepAlive: true,
        }, //超级和普通都能访问
        component: () =>
          import("@/pages/businessManagement/loopplan/index.vue"),
      },
      {
        path: "/businessManagement/Loopplan/edit",
        isshow: true,
        name: "LoopplanEdit",
        meta: {
          title: "回路计划编辑",
          isedit: true,
        }, //超级和普通都能访问
        component: () => import("@/pages/businessManagement/loopplan/edit.vue"),
      },
    ],
  },
  // 多媒体管理
  {
    path: "mutiMedia",
    meta: {
      title: "多媒体管理",
    }, //普通和超级都可以访问的一级路由
    isshow: true,
    component: Layout, // 布局Layout
    children: [
      {
        path: "/mutiMedia/mediaLib",
        meta: {
          title: "素材库",
          keepAlive: true,
        }, // 超级才能访问
        isshow: true,
        component: () =>
          import("@/pages/multimediaManage/materialLibrary/index.vue"),
      },
      {
        path: "/mutiMedia/mediaLib/edit",
        meta: {
          title: "素材库编辑",
          isedit: true,
        }, // 超级才能访问
        isshow: true,
        component: () =>
          import("@/pages/multimediaManage/materialLibrary/edit.vue"),
      },
      {
        path: "/mutiMedia/liveRadio",
        meta: {
          title: "在线广播",
          keepAlive: true,
        }, // 超级才能访问
        isshow: true,
        component: () => import("@/pages/multimediaManage/live/index.vue"),
      },
      {
        path: "/mutiMedia/liveRadio/edit",
        meta: {
          title: "在线广播编辑",
          isedit: true,
        }, // 超级才能访问
        isshow: true,
        component: () => import("@/pages/multimediaManage/live/edit.vue"),
      },
      {
        path: "/mutiMedia/mutiMediaPlan",
        meta: {
          title: "多媒体投放计划",
          keepAlive: true,
        }, // 超级才能访问
        isshow: true,
        component: () =>
          import("@/pages/multimediaManage/multimePlan/index.vue"),
      },
      {
        path: "/mutiMedia/mutiMediaPlan/calender",
        meta: {
          title: "多媒体投放日历",
        }, // 超级才能访问
        isshow: true,
        component: () =>
          import("@/pages/multimediaManage/multimePlan/calender.vue"),
      },
      {
        path: "/mutiMedia/mutiMediaPlan/edit",
        meta: {
          title: "多媒体投放计划编辑",
          isedit: true,
        }, // 超级才能访问
        isshow: true,
        component: () =>
          import("@/pages/multimediaManage/multimePlan/edit.vue"),
      },
      {
        path: "/mutiMedia/terminalCall",
        meta: {
          title: "一键呼叫",
          keepAlive: true,
        }, // 超级才能访问
        isshow: true,
        component: () => import("@/pages/multimediaManage/call/index.vue"),
      },
      {
        path: "/mutiMedia/terminalCall/edit",
        meta: {
          title: "一键呼叫编辑",
        }, // 超级才能访问
        isshow: true,
        component: () => import("@/pages/multimediaManage/call/edit.vue"),
      },
      {
        path: "/mutiMedia/terminalCall/detail",
        meta: {
          title: "一键呼叫详情",
        }, // 超级才能访问
        isshow: true,
        component: () => import("@/pages/multimediaManage/call/detail.vue"),
      },
    ],
  },
  // 资产管理
  {
    path: "/assetmanagement/DeviceList",
    meta: {
      title: "资产管理",
    }, //普通和超级都可以访问的一级路由
    redirect: "/assetmanagement/DeviceList",
    component: Layout, // 布局Layout
    isshow: true,
    children: [
      {
        path: "/assetmanagement/DeviceList",
        meta: {
          title: "设备列表",
          keepAlive: true,
        }, // 超级才能访问
        isshow: true,
        component: () => import("@/pages/assetmanagement/deviceList/index.vue"),
      },
      {
        path: "/assetmanagement/CentralizedController",
        isshow: true,
        meta: {
          title: "集中控制器",
          keepAlive: true,
        },
        component: () =>
          import("@/pages/assetmanagement/centralizedController/index.vue"),
      },
      {
        path: "/assetmanagement/CentralizedController/edit",
        isshow: true,
        name: "CentralizedControllerEdit",
        meta: {
          title: "集中控制器编辑",
          isedit: true,
        },
        component: () =>
          import("@/pages/assetmanagement/centralizedController/edit.vue"),
      },
      {
        path: "/assetmanagement/SingleController",
        isshow: true,
        meta: {
          title: "单灯控制器",
          keepAlive: true,
        }, //超级和普通都能访问
        component: () =>
          import("@/pages/assetmanagement/singleController/index.vue"),
      },
      {
        path: "/assetmanagement/SingleController/edit",
        isshow: true,
        name: "SingleControllerEdit",
        meta: {
          title: "单灯控制器编辑",
          isedit: true,
        }, //超级和普通都能访问
        component: () =>
          import("@/pages/assetmanagement/singleController/edit.vue"),
      },
      {
        path: "/assetmanagement/Controller/Details",
        isshow: true,
        meta: {
          title: "控制器详情",
          isedit: true,
        }, //超级和普通都能访问
        component: () =>
          import("@/pages/assetmanagement/deviceList/details.vue"),
      },
      {
        path: "/assetmanagement/ipRadio",
        meta: {
          title: "IP广播设备",
          keepAlive: true,
        }, // 超级才能访问
        isshow: true,
        component: () => import("@/pages/assetmanagement/ipRadio/index.vue"),
      },
      {
        path: "/assetmanagement/ipRadio/edit",
        meta: {
          title: "IP广播设备编辑",
          isedit: true,
        }, // 超级才能访问
        isshow: true,
        component: () => import("@/pages/assetmanagement/ipRadio/edit.vue"),
      },
      {
        path: "/assetmanagement/ipRadio/details",
        meta: {
          title: "IP广播设备详情",
          isedit: true,
        }, // 超级才能访问
        isshow: true,
        component: () => import("@/pages/assetmanagement/ipRadio/details.vue"),
      },
      {
        path: "/assetmanagement/camera",
        meta: {
          title: "监控设备",
          keepAlive: true,
        }, // 超级才能访问
        isshow: true,
        component: () => import("@/pages/assetmanagement/monitoring/index.vue"),
      },
      {
        path: "/assetmanagement/monitoring/details",
        isshow: true,
        meta: {
          title: "监控设备详情",
          isedit: true,
        }, //超级和普通都能访问
        component: () =>
          import("@/pages/assetmanagement/monitoring/details.vue"),
      },
      {
        path: "/assetmanagement/lampPost",
        meta: {
          title: "灯杆列表",
          keepAlive: true,
        }, // 超级才能访问
        isshow: true,
        component: () => import("@/pages/assetmanagement/lampPost/index.vue"),
      },
    ],
  },
  // 人员管理
  {
    path: "/personnel",
    meta: {
      title: "人员管理",
    },
    component: Layout, // 布局Layout
    children: [
      {
        path: "/personnel/personnelIndex",
        meta: {
          title: "员工列表",
          keepAlive: true,
        },
        component: () =>
          import("@/pages/employeeManage/employeeList/index.vue"),
      },
      {
        path: "/personnel/personnelIndex/edit",
        name: "personnelIndexEdit",
        meta: {
          title: "员工列表编辑",
          isedit: true,
        },
        component: () => import("@/pages/employeeManage/employeeList/edit.vue"),
      },
    ],
  },
  // 数据总览
  {
    path: "/Dataoverview",
    meta: {
      title: "数据总览",
    },
    component: Layout, // 布局Layout
    children: [
      {
        path: "/Dataoverview",
        component: () => import("@/pages/Dataoverview/index.vue"),
      },
    ],
  },
  // 资产统计
  {
    path: "/assetstatistics",
    meta: {
      title: "资产统计",
    },
    component: Layout, // 布局Layout
    children: [
      {
        path: "/assetstatistics",
        component: () => import("@/pages/assetstatistics/Index.vue"),
      },
      {
        path: "/assetstatistics/edit",
        meta: {
          title: "自定义资产统计",
        },
        component: () => import("@/pages/assetstatistics/edit.vue"),
      },
    ],
  },
  // 能耗统计
  {
    path: "/consumptionstatistics",
    meta: {
      title: "能耗统计",
    },
    component: Layout, // 布局Layout
    children: [
      {
        path: "/consumptionstatistics",
        component: () => import("@/pages/consumptionstatistics/Index.vue"),
      },
      {
        path: "/consumptionstatistics/edit",
        meta: {
          title: "自定义能耗统计",
        },
        component: () => import("@/pages/consumptionstatistics/edit.vue"),
      },
    ],
  },
  // 告警统计
  {
    path: "/alarmstatistics",
    meta: {
      title: "告警统计",
    },
    component: Layout, // 布局Layout
    children: [
      {
        path: "/alarmstatistics",
        component: () => import("@/pages/alarmstatistics/Index.vue"),
      },
    ],
  },
  // 工单统计
  {
    path: "/workorderstatistics",
    meta: {
      title: "工单统计",
    },
    component: Layout, // 布局Layout
    children: [
      {
        path: "/workorderstatistics",
        component: () => import("@/pages/workorderstatistics/Index.vue"),
      },
    ],
  },
  // 系统设置
  {
    path: "/SystemSetting",
    meta: {
      title: "系统设置",
    }, // 超级才能访问
    component: Layout, // 布局Layout
    children: [
      {
        path: "/SystemSetting/department",
        meta: {
          title: "部门管理",
          keepAlive: true,
        }, // 超级才能访问
        component: () => import("@/pages/SystemSetting/department/index.vue"),
      },
      {
        path: "/SystemSetting/department/edit",
        name: "OrganizationalDateEdit",
        meta: {
          title: "部门管理编辑",
          isedit: true,
        }, // 超级才能访问
        component: () => import("@/pages/SystemSetting/department/edit.vue"),
      },
      {
        path: "/SystemSetting/OrganizationalDate",
        meta: {
          title: "组织信息",
          keepAlive: true,
        }, // 超级才能访问
        component: () =>
          import("@/pages/SystemSetting/organizationalDate/index.vue"),
      },
      {
        path: "/SystemSetting/Permission",
        meta: {
          title: "角色权限",
          keepAlive: true,
        }, // 超级才能访问
        component: () => import("@/pages/SystemSetting/permission/index.vue"),
      },
      {
        path: "/SystemSetting/Permission/edit",
        name: "PermissionEdit",
        meta: {
          title: "角色权限编辑",
          isedit: true,
        }, // 超级才能访问
        component: () => import("@/pages/SystemSetting/permission/edit.vue"),
      },
      {
        path: "/SystemSetting/Record",
        meta: {
          title: "操作记录",
        }, // 超级才能访问
        component: () => import("@/pages/SystemSetting/record/index.vue"),
      },
      {
        path: "/SystemSetting/Record/detail",
        meta: {
          title: "操作记录详情",
        }, // 超级才能访问
        component: () => import("@/pages/SystemSetting/record/detail.vue"),
      },
    ],
  },
  {
    path: "/building",
    meta: {
      title: "开发中",
    }, // 超级才能访问
    component: Layout, // 布局Layout
    children: [
      {
        path: "/building/index",
        meta: {
          title: "该功能正在开发中",
        }, // 超级才能访问
        component: () => import("@/pages/buildingcode/index.vue"),
      },
    ],
  },
  // 项目管理
  {
    path: "/Projectmanagement",
    meta: {
      title: "项目管理",
    }, //普通和超级都可以访问的一级路由
    redirect: "/Projectmanagement/projectlist",
    component: Layout, // 布局Layout
    children: [
      {
        path: "/Projectmanagement/projectlist",
        meta: {
          title: "项目列表",
          keepAlive: true,
        }, // 超级才能访问
        component: () =>
          import("@/pages/Projectmanagement/projectlist/index.vue"),
      },
      {
        path: "/Projectmanagement/projectlist/edit",
        name: "projectlistEdit",
        meta: {
          title: "项目列表编辑",
          isedit: true,
        }, // 超级才能访问
        component: () =>
          import("@/pages/Projectmanagement/projectlist/edit.vue"),
      },
      {
        path: "/Projectmanagement/arealist",
        meta: {
          title: "片区列表",
          keepAlive: true,
        }, // 超级才能访问
        component: () => import("@/pages/Projectmanagement/arealist/index.vue"),
      },
      {
        path: "/Projectmanagement/arealist/edit",
        name: "arealistEdit",
        meta: {
          title: "片区列表编辑",
          isedit: true,
        }, // 超级才能访问
        component: () => import("@/pages/Projectmanagement/arealist/edit.vue"),
      },
    ],
  },
  // 建维管理
  {
    path: "/buildMaintenance",
    meta: {
      title: "建维管理",
    }, //普通和超级都可以访问的一级路由
    component: Layout, // 布局Layout
    children: [
      {
        path: "/buildMaintenance/taskorder",
        meta: {
          title: "任务工单",
        }, // 超级才能访问
        component: () => import("@/pages/buildMaintenance/taskorder/index.vue"),
      },
      {
        path: "/buildMaintenance/taskorder/detail",
        meta: {
          title: "任务工单",
        }, // 超级才能访问
        component: () =>
          import("@/pages/buildMaintenance/taskorder/detail.vue"),
      },
      {
        path: "/waitbulid/index",
        meta: {
          title: "待建计划",
        }, // 超级才能访问
        component: () => import("@/pages/buildMaintenance/waitBuild/index.vue"),
      },
      {
        path: "/waitbulid/addbuild",
        meta: {
          title: "新建施工任务",
        }, // 超级才能访问
        component: () =>
          import("@/pages/buildMaintenance/waitBuild/addbuild.vue"),
      },
      {
        path: "/waitbulid/buildtask",
        meta: {
          title: "建设任务列表",
        }, // 超级才能访问
        component: () => import("@/pages/buildMaintenance/buildtask/index.vue"),
      },
      {
        path: "/waitbulid/equipmentwarning",
        meta: {
          title: "设备告警",
        }, // 超级才能访问
        component: () =>
          import("@/pages/buildMaintenance/equipmentwarning/index.vue"),
      },
      {
        path: "/waitbulid/equipmentwarning/edit",
        meta: {
          title: "告警任务",
        }, // 超级才能访问
        component: () =>
          import("@/pages/buildMaintenance/equipmentwarning/edit.vue"),
      },
    ],
  },
  // 告警设置
  {
    path: "/warningset",
    meta: {
      title: "告警设置",
    }, //普通和超级都可以访问的一级路由
    component: Layout, // 布局Layout
    children: [
      {
        path: "/warningset/index",
        meta: {
          title: "告警设置",
        }, // 超级才能访问
        component: () => import("@/pages/warningset/index.vue"),
      },
      {
        path: "/warningset/singlecontrolset",
        meta: {
          title: "单灯控制器告警项设置",
        }, // 超级才能访问
        component: () => import("@/pages/warningset/singlecontrolset.vue"),
      },
      {
        path: "/warningset/concentset",
        meta: {
          title: "集中控制器告警项设置",
        }, // 超级才能访问
        component: () => import("@/pages/warningset/concentset.vue"),
      },
    ],
  },
  // 消息订阅
  {
    path: "/submsg",
    meta: {
      title: "消息订阅",
    }, //普通和超级都可以访问的一级路由
    component: Layout, // 布局Layout
    children: [
      {
        path: "/submsg/index",
        meta: {
          title: "消息订阅",
        }, // 超级才能访问
        component: () => import("@/pages/submsg/index.vue"),
      },
      {
        path: "/submsg/subset",
        meta: {
          title: "消息订阅/配置",
        }, // 超级才能访问
        component: () => import("@/pages/submsg/subset.vue"),
      },
    ],
  },
  // 数据大屏
  {
    path: "/consumptionstatistics/pdf",
    name: "consumptionstatisticspdf",
    meta: {
      title: "统计报告",
    }, //普通和超级都可以访问的一级路由
    // component: Layout, // 布局Layout
    component: () => import("@/pages/consumptionstatistics/pdf.vue"),
  },
  {
    path: "/assetstatistics/pdf",
    name: "assetstatisticspdf",
    meta: {
      title: "统计报告",
    }, //普通和超级都可以访问的一级路由
    // component: Layout, // 布局Layout
    component: () => import("@/pages/assetstatistics/pdf.vue"),
  },
  {
    path: "/screen",
    meta: {
      title: "数据大屏",
    }, //普通和超级都可以访问的一级路由
    // component: Layout, // 布局Layout
    component: () => import("@/pages/dataScreen/index.vue"),
  },
  {
    path: "/404",
    component: () => import("@/pages/Erro404.vue"),
  },
  // {
  //   path: "*",
  //   redirect: "/404",
  // },
];
