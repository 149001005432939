<template>
  <el-menu
    class="el-menu-vertical-demo"
    ref="menu"
    background-color="#21319a"
    text-color="#fff"
    active-text-color="#409EFF"
    :default-openeds="defaultOpeneds"
  >
    <div v-for="(item, index) in menu" :key="index">
      <template v-if="item.child && item.child.length">
        <el-submenu :index="item.path" v-if="item.is_permission">
          <template slot="title">
            <span>{{ item.name || "" }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              v-for="(c, j) in item.child"
              :key="j"
              @click="menuClick(c, item)"
              :class="{ xuan: c.id == cureentMenuChild.id }"
            >
              <a :href="c.id" @click.prevent>{{ c.name || "" }}</a>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </template>
      <template v-else>
        <el-menu-item
          @click="menuClick(item)"
          :class="{ xuan: item.id == cureentMenuChild.id }"
          v-if="item.is_permission"
        >
          <a :href="item.id" @click.prevent>{{ item.name || "" }}</a>
        </el-menu-item>
      </template>
    </div>
  </el-menu>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import local from "@/utils/local.js";
export default {
  data() {
    return {
      menu: [],
      defaultOpeneds: [],
    };
  },
  created() {},
  computed: {
    ...mapState({
      menus: (state) => state.menuContron.menus,
      cureentMenuParent: (state) => state.menuContron.cureentMenuParent,
      cureentMenuChild: (state) => state.menuContron.cureentMenuChild,
      isReload: (state) => state.menuContron.isReload,
    }),
  },
  watch: {
    menus: {
      handler(newVal) {
        console.log("菜单改变");
        this.menu = newVal;
        this.open();
        this.defaChoose();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      setCureentMenuParent: "menuContron/setCureentMenuParent",
      setCureentMenuChild: "menuContron/setCureentMenuChild",
      setIsReload: "menuContron/setIsReload",
    }),
    // 默认展开
    open() {
      // 全部
      if (Object.keys(this.menu).length) {
        let arr = [];
        this.menu.forEach((item) => {
          if (item.child && item.child.length) {
            arr.push(item.path);
          }
        });
        this.defaultOpeneds = [...arr];
        // 单个展开
        // if (!this.cureentMenuParent.path) {
        // 	this.defaultOpeneds = [this.menu[0]?.path ?? ""];
        // 	this.setCureentMenuParent(this.menu[0]);
        // } else {
        // 	this.defaultOpeneds = [this.cureentMenuParent.path]
        // }
      }
    },
    // 子菜单默认选中
    defaChoose() {
      if (this.cureentMenuChild && Object.keys(this.menu).length) {
        let child = {};
        if (!this.cureentMenuChild.path) {
          child = this.menu[0]?.child[0] ?? this.menu[0];
          this.setCureentMenuChild(child);
        } else {
          child = this.cureentMenuChild;
        }
        if (this.isReload) {
          child.is_permission && this.$router.push(child.path);
        }
      }

      localStorage.setItem("store", JSON.stringify(this.$store.state));
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    menuClick(val, data) {

      let arr = JSON.parse(local.get("asyncRoutes"));

      let i = arr.findIndex((item) => item.path == val.path);
      console.log(val.path);
      console.log(arr)
      console.log(i)
      if (i != -1) {
        this.$router.push(val.path);
        localStorage.setItem("store", JSON.stringify(this.$store.state));

        this.setCureentMenuParent(data);
        this.setCureentMenuChild(val);
      }else{
        this.$message.warning("该菜单功能正在研发中");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  height: 100%;
  margin-bottom: 300px;
}
::v-deep .el-menu {
  height: 100% !important;
  width: 250px !important;

}

::v-deep .el-menu-item,
::v-deep .el-submenu__title {
  color: #fff !important;
  font-size: 16px !important;

  span {
    font-size: 16px !important;
  }
}

::v-deep .el-menu-item {
  height: 55px !important;
  line-height: 55px !important;
}

::v-deep .el-submenu__title {
  padding-left: 55px !important;
}

::v-deep .el-menu-item {
  padding-left: 75px !important;
  color: rgba($color: #fff, $alpha: 0.7) !important;
}

::v-deep .xuan {
  background-color: #4759d8 !important;
  color: rgba($color: #fff, $alpha: 1.0) !important;
}
</style>
